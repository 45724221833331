<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-pencil
      </v-icon> Edit Campaign
    </h2>
    <br>
    <div>
      <delete-banner
        v-if="showBannerDelete"
        :title="'Delete this content from current campaign?'"
        @closed="showBannerDelete = false"
        @confirmed="deleteBannerConfirmed()"
      />
      <upload-files-on-edit
        v-if="showUploadForm"
        @closed="showUploadForm = false"
      />
      <delete-added-content
        v-if="showAddedContentDelete"
        :title="'Delete this newly added content ?'"
        @closed="showBannerDelete = false"
        @confirmed="deleteAddedContentConfirmed()"
      />
      <banner-upload-list
        ref="callmethod"
        v-model="showMediaFiles"
      />
    </div>
    <v-alert
      v-if="!permissionCheck('read-campaign')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action as you require <span class="permission-text-style">"read-campaign"</span> permission. Please contact your administrator.
    </v-alert>
    <br>
    <v-stepper
      v-model="campaignEdit"
      shaped
      class="my-stepper-style"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="campaignEdit > 1"
          step="1"
          color="#FF3700"
        >
          Campaign Edit
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          step="2"
          color="#FF3700"
        >
          Device Edit
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <div class="text-right">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#FF3700"
                v-bind="attrs"
                v-on="on"
              >
                Add Content
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="uploadFiles()">
                <v-list-item-title>Upload</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openMediaFiles()">
                <v-list-item-title>Media Files</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <p class="content-header">
                Campaign Contents
              </p>
              <v-container>
                <v-data-table
                  :headers="headers"
                  :items="campaignContent.banners"
                  class="elevation-1"
                >
                  <template
                    v-slot:item="{ item }"
                  >
                    <tr>
                      <td>{{ item.content.original_file_name }}</td>
                      <td>{{ item.content.size | sizeConverter }}</td>
                      <td>{{ item.content.dimension | dimensionFormatter }}</td>
                      <td>
                        <div v-if="item.content.content_type === 'image'">
                          <div class="sliderContainer">
                            <v-slider
                              v-model="rotationIntervals[item.id]"
                              max="30"
                              min="2"
                              color="#FF3700"
                              thumb-label
                            />
                          </div>
                          <p>{{ rotationIntervals[item.id] }} seconds</p>
                        </div>
                        <div v-else>
                          <p>{{ item.content.duration | durationConverter }}</p>
                        </div>
                      </td>
                      <td>
                        <div v-if="isContentImgOrGif(item.content.content_type)">
                          <v-img
                            width="100"
                            height="100"
                            :src="getFilePath(item.content.file_path)"
                            contain
                            @click="popUpImage(item.content.file_path)"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#FF3700"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                        <div v-else>
                          <video
                            width="100"
                            height="100"
                            contain
                            controls
                          >
                            <source
                              :src="getFilePath(item.content.file_path)"
                              type="video/mp4"
                            >
                          </video>
                        </div>
                      </td>
                      <td>
                        <v-col>
                          <v-btn
                            class="mt-n2"
                            elevation="1"
                            fab
                            x-small
                            icon
                            color="#C62828"
                            @click.stop
                            @click="deleteFile( item.id, item.boards)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-col>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="isContentsAdded">
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <p class="content-header">
                Added Contents
              </p>
              <v-container>
                <v-data-table
                  :headers="addedContents.headers"
                  :items="campaignFiles"
                  class="elevation-1"
                >
                  <template
                    v-slot:item="{ item, index }"
                  >
                    <tr>
                      <td>{{ item.formData.original_file_name }}</td>
                      <td>{{ item.formData.size | sizeConverter }}</td>
                      <td>{{ item.formData.dimension | dimensionFormatter }}</td>
                      <td>
                        <div v-if="item.formData.content_type === 'image'">
                          <div class="sliderContainer">
                            <v-slider
                              v-model="item.rotation_interval"
                              max="30"
                              min="2"
                              color="#FF3700"
                              thumb-label
                            />
                            <p>{{ item.rotation_interval }} seconds</p>
                          </div>
                        </div>
                        <div v-else>
                          <p>{{ item.formData.duration | durationConverter }}</p>
                        </div>
                      </td>
                      <td>
                        <div v-if="isContentImgOrGif(item.formData.content_type)">
                          <v-img
                            width="100"
                            height="100"
                            contain
                            :src="getFilePath(item.formData.file_path)"
                            @click="popUpImage(item.formData.file_path)"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#FF3700"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                        <div v-else>
                          <video
                            width="100"
                            height="100"
                            contain
                            controls
                          >
                            <source
                              :src="getFilePath(item.formData.file_path)"
                              type="video/mp4"
                            >
                          </video>
                        </div>
                      </td>
                      <td>
                        <v-col>
                          <v-btn
                            class="mt-n2"
                            elevation="1"
                            fab
                            x-small
                            icon
                            color="#C62828"
                            @click.stop
                            @click="deleteCampaignFile(index)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-col>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-card
            shaped
            color="#ECEFF1"
          >
            <br>
            <p class="content-header">
              Campaign Details
            </p>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="campaignName"
                        label="Campaign Name*"
                        hint="Name for your reference - 'MarketingCampaign'(Only alpha dashes allowed)."
                        :rules="[(v) => !!v || 'This field is required']"
                        type="text"
                        @keydown.space.prevent
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-textarea
                        v-model="comment"
                        label="Campaign-Comment"
                        hint="Your reference - 'Run weekly across the company'."
                        outlined
                        rows="3"
                        row-height="25"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="start date"
                      >
                        <v-datetime-picker
                          ref="start"
                          v-model="start"
                          label="Dispalyed From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            min: minStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              large
                              color="#37474F"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                          <template v-slot:actions>
                            <v-btn
                              color="grey lighten-1"
                              text
                              @click.native="cancelStartDialog"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="submitStartDialog"
                            >
                              Ok
                            </v-btn>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <v-datetime-picker
                        ref="end"
                        v-model="end"
                        label="Displayed To*"
                        :text-field-props="{
                          hint: 'Leave blank if never ends',
                          persistentHint: true,
                          suffix: userTimeZone
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                        <template v-slot:actions>
                          <v-btn
                            color="red darken-1"
                            text
                            @click.native="clearEndDialog"
                          >
                            Clear
                          </v-btn>
                          <v-btn
                            color="grey lighten-1"
                            text
                            @click.native="cancelEndDialog"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="submitEndDialog"
                          >
                            Ok
                          </v-btn>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-switch
                        v-model="global"
                        label="Global Campaign"
                        hint="Organizational level campaign."
                        :persistent-hint="true"
                        color="#FF3700"
                      />
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    class="text-left"
                  >
                    <v-btn
                      v-if="showScheduleBtn"
                      small
                      dark
                      class="mt-2"
                      color="#FF3700"
                      depressed
                      default
                      rounded
                      @click="showAdvancedSchedule = true"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Advanced Schedule
                    </v-btn>
                    <advanced-scheduling v-model="showAdvancedSchedule" />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <advanced-scheduling-list @show-content-form="showContentForm" />
                    <br>
                    <advanced-schedule-edit-before-creation
                      v-if="showScheduleContent"
                      :editvalues="editScheduleContent"
                      :scheduleindex="scheduleIndex"
                      @closed="showScheduleContent = false"
                    />
                    <br>
                    <advanced-schedule-list-for-edit @show-form="showForm" />
                    <advanced-schedule-form-for-edit
                      v-if="showEdit"
                      :editvalues="editScheduleInfo"
                      @closed="showEdit = false"
                      @get-latest="fetchAdvancedScheduleForEdit"
                    />
                  </v-col>
                </v-container>
                <p class="text-sm-left">
                  *Indicates required field
                </p>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                @click="back()"
              >
                Back
              </v-btn>
              <v-spacer />
              <v-btn
                color="#FF3700"
                @click="next()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <edit-selected-digi-boards
          v-if="campaignEdit >= 2"
          :campaign-form-details="campaignFormDetails"
        />
      </v-stepper-content>
      <centre-spinner
        :loading="loading"
      />
      <image-preview
        v-if="showPreview"
        :url="previewUrl"
        @closed="showPreview = false"
      />
    </v-stepper>
    <br>
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { cdnUrl } from 'src/app-globals';
  import ImagePreview from '../component/ImagePreview';
  import AdvancedScheduling from '../component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from '../component/schedules/AdvancedSchedulingList.vue';
  import moment from 'moment-timezone';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';
  import AdvancedScheduleListForEdit from '../component/schedules/AdvancedScheduleListForEdit.vue';
  import AdvancedScheduleFormForEdit from '../component/schedules/AdvancedScheduleFormForEdit.vue';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import EditSelectedDigiBoards from './EditSelectedDigiBoard.vue';
  import AdvancedScheduleEditBeforeCreation from '../component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import global from 'src/mixins/global';
  import UploadFilesOnEdit from './UploadFilesOnEdit.vue';
  import BannerUploadList from './BannerUploadList.vue';

  export default {
    name: 'EditCampaign',
    components: {
      'centre-spinner': spinner,
      'image-preview': ImagePreview,
      'delete-banner': DeleteDialog,
      AdvancedScheduling,
      AdvancedSchedulingList,
      AdvancedScheduleListForEdit,
      AdvancedScheduleFormForEdit,
      EditSelectedDigiBoards,
      AdvancedScheduleEditBeforeCreation,
      'upload-files-on-edit': UploadFilesOnEdit,
      'delete-added-content': DeleteDialog,
      'banner-upload-list': BannerUploadList,
    },
    filters: {
      sizeConverter (val) {
        return val / 1000 + ' kb ';
      },
      durationConverter (val) {
        return val / 1000 + ' seconds';
      },
      dimensionFormatter (val) {
        if (val === null) {
          return 'Not available';
        }

        return `${val} px`;
      },
    },
    mixins: [global],
    data () {
      return {
        campaignEdit: 1,
        showAdvancedSchedule: false,
        loading: false,
        headers: [
          { text: 'File Name', value: 'original_file_name' },
          { text: 'Size', value: 'size' },
          { text: 'Dimension', value: 'dimension' },
          { text: 'Rotation Interval', value: 'rotation_interval' },
          { text: 'Content' },
          { text: 'Delete', value: 'delete' },
        ],
        showPreview: false,
        previewUrl: '',
        minStartDate: moment().format('YYYY-MM-DD'),
        start: '',
        end: '',
        campaignName: '',
        helper: new ModuleHelper(),
        showEdit: false,
        editScheduleInfo: {},
        showBannerDelete: false,
        deleteBannerId: '',
        deleteCampaignId: '',
        comment: '',
        global: false,
        rotationIntervals: {},
        showScheduleContent: false,
        scheduleIndex: 0,
        editScheduleContent: {},
        boardExternalIds: [],
        campaignFormDetails: {
          campaignName: '',
          comment: '',
          start: '',
          end: '',
          rotationIntervals: {},
          global: false,
        },
        showUploadForm: false,
        addedContents: {
          headers: [
            { text: 'File Name', value: 'original_file_name' },
            { text: 'Size', value: 'size' },
            { text: 'Dimension', value: 'dimension' },
            { text: 'Rotation Interval', value: 'rotation_interval' },
            { text: 'Content' },
            { text: 'Delete', value: 'delete' },
          ],
        },
        showAddedContentDelete: false,
        addedContentIndex: '',
        showMediaFiles: false,
      };
    },
    computed: {
      campaignContent () {
        return this.$store.getters['campaigns/getCampaignContent'];
      },
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExist(Constants.MODULE_CAMPAIGN, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      advancedScheduleDataForEdit () {
        return this.$store.getters['schedule/getAdvancedScheduleForEdit'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      campaignFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      isContentsAdded () {
        if (this.campaignFiles.length > 0) {
          return true;
        }
        return false;
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('edit-campaign') === false || vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
      this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      this.$store.dispatch('campaigns/clearCampaignContent');
      this.$store.dispatch('schedule/clearAdvancedSchedule');
      this.permissionCheck('read-campaign') && await this.fillData();
      this.permissionCheck('read-campaign') && this.setRotationIntervals();
    },
    methods: {
      async fillData () {
        this.loading = true;
        const campaignId = this.$route.params.id;
        await this.$store.dispatch('campaigns/fetchCampaignContent', {
          id: campaignId,
        });
        this.campaignName = this.campaignContent.name;
        this.comment = this.campaignContent.comment;
        this.global = this.campaignContent.global;
        this.start = this.convertStringToLocalDatetime(this.campaignContent.start);
        this.end = this.campaignContent.end
        ? this.convertStringToLocalDatetime(this.campaignContent.end)
        : null;
        this.permissionCheck('find-weekly-schedule') && await this.fetchAdvancedScheduleForEdit();
        this.loading = false;
      },
      back () {
        this.$router.push({ name: 'Campaign List' });
        this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
        this.$store.dispatch('campaigns/clearCampaignContent');
        this.$store.dispatch('schedule/clearAdvancedSchedule');
        this.$store.dispatch('campaigns/clearCampaignFile');
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      popUpImage (fileName) {
        this.previewUrl = this.getFilePath(fileName);
        this.showPreview = true;
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().toDate();
      },
      async fetchAdvancedScheduleForEdit () {
        this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
        if (this.campaignContent.schedules.length > 0) {
          for (const advancedSchedule of this.campaignContent.schedules) {
            await this.$store.dispatch('schedule/fetchAdvancedScheduleForEdit', advancedSchedule.id);
          }
        }
      },
      showForm (val) {
        this.editScheduleInfo = this.advancedScheduleDataForEdit.find(
          schedule => schedule.id === val,
        );
        this.showEdit = true;
      },
      setRotationIntervals () {
        const campaign = this.campaignContent;
        for (const banner of campaign.banners) {
          this.rotationIntervals[banner.id] = banner.rotation_interval / 1000;
        }
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancelStartDialog () {
        this.$refs.start.resetPicker();
        this.$refs.start.init();
      },
      submitStartDialog () {
        this.$refs.start.okHandler();
      },
      cancelEndDialog () {
        this.$refs.end.resetPicker();
        this.$refs.end.init();
      },
      submitEndDialog () {
        this.$refs.end.okHandler();
      },
      clearEndDialog () {
        this.$refs.end.clearHandler();
      },
      deleteFile (bannerId, boardExternalIds) {
        if (this.permissionCheck('remove-campaign-by-bannerId') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (this.campaignContent.banners.length === 1) {
          this.$store.dispatch('alert/onAlert', {
            message: 'At least one content is required to run the campaign.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showBannerDelete = true;
        this.deleteBannerId = bannerId;
        this.deleteCampaignId = this.campaignContent.id;
        this.boardExternalIds = boardExternalIds;
      },
      async deleteBannerConfirmed () {
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        this.$store.dispatch('campaigns/removeBannerFromCampaign', {
          campaignId: this.deleteCampaignId,
          bannerId: this.deleteBannerId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Content removed from campaign.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showBannerDelete = false;
          this.fillData();
        }).finally(
          this.deleteBannerId = '',
          this.deleteCampaignId = '',
        );
      },
      next () {
        if (this.permissionCheck('read-campaign') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action as you require "read-campaign" permission. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          this.$router.push({ name: 'Campaign List' });
          return;
        }
        if (this.start === null || this.start === '' || this.campaignName === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.campaignFormDetails.campaignName = this.campaignName;
        this.campaignFormDetails.comment = this.comment;
        this.campaignFormDetails.start = this.start;
        this.campaignFormDetails.end = this.end;
        this.campaignFormDetails.rotationIntervals = this.rotationIntervals;
        this.campaignFormDetails.global = this.global;
        setTimeout(() => {
          this.loading = false;
          this.campaignEdit = 2;
        }, 2000);
      },
      uploadFiles () {
        if (this.permissionCheck('upload-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showUploadForm = true;
      },
      deleteCampaignFile (index) {
        this.showAddedContentDelete = true;
        this.addedContentIndex = index;
      },
      async deleteAddedContentConfirmed () {
        await this.$store.dispatch('campaigns/deleteCampaignFile', {
          index: this.addedContentIndex,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Content deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$refs.callmethod.removeBadge(this.addedContentIndex);
        }).finally(
          this.showAddedContentDelete = false,
        );
      },
      openMediaFiles () {
        if (this.permissionCheck('read-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showMediaFiles = true;
      },
    },
  };
</script>
<style scoped>
.sliderContainer {
  width: 150px !important;
}
.my-stepper-style {
  background-color: #CFD8DC !important;
  margin-right: 30px;
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.permission-text-style {
  font-family: 'Times New Roman', Times, serif;
  color: #37474F;
}
.v-alert .v-alert__wrapper .v-alert__content span {
  font-size: 16px;
}
.content-header {
  color: #546E7A;
  font-size: 20px;
  margin-left: 30px !important;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
</style>
